const r5Matchups = [
  {
    contenderA:
      {
        mascot: "Digi \"The Dinosaur\"",
        explanation: "Digi -short for digital.\n" +
          "\n" +
          "Dinosaurs made a mark in history and our company aims to make their mark in history as the best digital agency in the nation! Dinosaurs were a force to be reckon with!",
        image: "mascots/party-dinosaur.gif"
      },
    contenderB:
      {
        mascot: "A Round On The House",
        explanation: "Cheers to you! Nothing says \"thank you\" like having someone else pay for your beverage of choice.",
        image: "mascots/beers.webp"
      },
  },
];

export default r5Matchups;