import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';



const Mascot = ({imagePath, alt, className, size}) => {

  let query = graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        publicURL
                        name
                        childImageSharp {
                            fixed(height: 128) {
                                ...GatsbyImageSharpFixed
                            }
                            fluid(maxHeight: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
  `

  return (

  <StaticQuery
    query={query}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(imagePath);
      });

      if (imagePath.substr(imagePath.lastIndexOf('.') + 1) === "gif") {
        return (
          <div className={className}>
            <img alt={alt} src={image.node.publicURL}/>
          </div>
        )
      }

      if (!image) {
        return null;
      }

      if ( size === "fixed" )
      return (
        <Img
          className={className}
          alt={alt}
          fixed={image.node.childImageSharp.fixed}
        />
      )

      return (
        <Img
          className={className}
          alt={alt}
          fluid={image.node.childImageSharp.fluid}
        />
      )

    }}
  />
)}

export default Mascot