import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const config = {
  apiKey: "AIzaSyATsJUX6A91of9MwMD54q7hdCnScKgH-io",
  authDomain: "mascot-madness-2d342.firebaseapp.com",
  databaseURL: "https://mascot-madness-2d342.firebaseio.com",
  projectId: "mascot-madness-2d342",
  storageBucket: "mascot-madness-2d342.appspot.com",
  messagingSenderId: "49281751838",
  appId: "1:49281751838:web:bfb5f976206ff440bc070f",
  measurementId: "G-K3GBHDEY1E"
}

class Firebase {
  constructor() {
    firebase.initializeApp(config)

    this.store = firebase.firestore()
    this.auth = firebase.auth
  }

}

export default new Firebase();